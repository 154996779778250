import { getTransport } from "../clients";
import { profileMenu } from "../menus";
import { Router, context } from "./../router";
import { downloadData, handlePageForInvalidPermissions, interceptPage, randomId, setupSidebarMenus } from "./../utilities";
import * as ui from "../ui";
import { getClientForVendorsService, Vendor } from "@kernelminds/scailo-sdk";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(profileMenu.href, async (ctx) => {
        if (await interceptPage(ctx, profileMenu)) {
            handleProfilePage(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}

async function handleProfilePage(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    let vendorUuid = localStorage.getItem("vendor_uuid") || "";
    let client = getClientForVendorsService(transport);
    let vendorInfo = await client.viewByUUID({ uuid: vendorUuid });

    let { html, formId, downloadButtonId, downloadCSVButtonId } = await _renderPageFilters(vendorInfo);
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    let downloadButton = <HTMLButtonElement>document.getElementById(downloadButtonId);

    downloadButton.addEventListener("click", async evt => {
        evt.preventDefault();

        const originalButtonHTML = downloadButton.innerHTML;
        downloadButton.disabled = true;
        downloadButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
        let file = await client.downloadByUUID({ uuid: vendorUuid });

        downloadButton.disabled = false;
        downloadButton.innerHTML = originalButtonHTML;

        downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
    });

    let downloadCSVButton = <HTMLButtonElement>document.getElementById(downloadCSVButtonId);

    downloadCSVButton.addEventListener("click", async evt => {
        evt.preventDefault();

        const originalButtonHTML = downloadCSVButton.innerHTML;
        downloadCSVButton.disabled = true;
        downloadCSVButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
        let file = await client.downloadItemsAsCSV({ uuid: vendorUuid });

        downloadCSVButton.disabled = false;
        downloadCSVButton.innerHTML = originalButtonHTML;

        downloadData(file.content, "csv", file.name.replace(".csv", ""));
    });
}

function renderPrimaryFiltersSection(vendor: Vendor) {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Info", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Name", inputType: "text", dataMapper: "name", dataType: "string", value: vendor.name, mdColSpan: 12, helpText: "The name of the vendor.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Code", inputType: "text", dataMapper: "code", dataType: "string", value: vendor.code, mdColSpan: 12, helpText: "The code of the vendor.", dataRegex: ".*" }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Email", inputType: "email", dataMapper: "email", dataType: "string", value: vendor.email, mdColSpan: 6, helpText: "The primary email address of the vendor.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), readonly: true, label: "Phone", inputType: "tel", dataMapper: "phone", dataType: "string", value: vendor.phone, mdColSpan: 6, helpText: "The primary phone number of the vendor.", dataRegex: ".*" }));

    return grid
}

async function renderDynamicFormsSection(vendor: Vendor, readonly: boolean): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(ui.renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await ui.returnFormFromFields(vendor.formData, "vendors", true, readonly);

    container.appendChild(formFields);

    return container;
}

export async function _renderPageFilters(vendor: Vendor) {
    let primaryFiltersSection = renderPrimaryFiltersSection(vendor);


    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let downloadButtonId = randomId();
    let downloadButton = document.createElement("button");
    downloadButton.id = downloadButtonId;
    downloadButton.className = "btn btn-outline btn-success btn-md mr-4";
    downloadButton.innerText = `Download`;
    buttonsDiv.append(downloadButton);

    let downloadCSVButtonId = randomId();
    let downloadCSVButton = document.createElement("button");
    downloadCSVButton.id = downloadCSVButtonId;
    downloadCSVButton.className = "btn btn-outline btn-primary btn-md mr-4";
    downloadCSVButton.innerText = `Download CSV`;
    buttonsDiv.append(downloadCSVButton);

    const formId = randomId();

    const [
        dynamicFormsSection
    ] = await Promise.all([
        renderDynamicFormsSection(vendor, true),
    ])

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Vendor Profile" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    ${dynamicFormsSection.outerHTML}
                </div>
            </div>
        `,
        formId,
        downloadButtonId,
        downloadCSVButtonId
    }
}