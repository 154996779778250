import { context } from "./../../router";
import { VendorStreamsService, VendorStreamsServiceCreateRequest, PermissionVendorStreamNew, Role, getClientForVendorStreamsService, VENDOR_STREAM_REF_FROM, getClientForPurchasesOrdersService, getClientForGoodsReceiptsService, getClientForVendorInvoicesService, getClientForPurchasesReturnsService, getClientForDebitNotesService, getClientForPurchasesPaymentsService, getClientForSupplyOffersService, STANDARD_LIFECYCLE_STATUS } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForVendorStream, redirectTo, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSelect, renderSelectForEntireEnum, renderSelectForPartialEnum } from "../../ui";
import { checkForAnyPerm, createObjectFromForm, decodeVendorStreamRefFrom, getDynamicFormData, getUserRole, randomId, showFailureAlert, showSuccessAlert, toTitleCase, validateForm } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { getTransport, getWriteTransport } from "../../clients";
import { getFormsFields, roleSelf } from "../../fetches";
import { setupDebitNoteSearchable, setupGoodsReceiptSearchable, setupPurchaseOrderSearchable, setupPurchasePaymentSearchable, setupPurchaseReturnSearchable, setupSupplyOfferSearchable, setupVendorInvoiceSearchable } from "../../searchables";

export async function handleCreateVendorStream(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForVendorStreamsService(transport);

    document.title = `Create Stream`;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    let [userRole] = await Promise.all([
        getUserRole(),
    ]);

    const { 
        formGrid, nextButtonId, resetButtonId, 
        refFromElementId, refIdElementId 
    } = await getForm(accessClient, userRole, transport);

    container.appendChild(formGrid);

    // Setup searchable (for the first selected item)
    handleRefFromChange(refFromElementId, refIdElementId, "init");

    // Setup change event handler for refFrom
    (<HTMLSelectElement>document.getElementById(refFromElementId)).addEventListener("change", evt => {
        handleRefFromChange(refFromElementId, refIdElementId, "second");
    });

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleCreateVendorStream(ctx);
    });

    const nextButton = (<HTMLButtonElement>document.getElementById(nextButtonId));

    if (checkForAnyPerm(userRole, [PermissionVendorStreamNew])) {
        nextButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Validate all the fields
            if (!validateForm(formGrid.id)) {
                return;
            }
            nextButton.disabled = true;
            nextButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

            // Create the object

            const writeClient = getClientForVendorStreamsService(getWriteTransport());
            let obj = <VendorStreamsServiceCreateRequest>createObjectFromForm(formGrid.id);
            obj.assignSelfAsVendorSubscriber = true;
            try {
                let resp = await writeClient.create(obj)
                showSuccessAlert(`Stream - ${obj.title} has been created. Redirecting you to the edit page in 1 second...`);
                setTimeout(() => {
                    redirectTo(getLinkForVendorStream(resp.uuid), true);
                }, 1000)
            } catch (e) {
                nextButton.disabled = false;
                nextButton.innerText = `Next`;
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }
}

const transport = getTransport();
const purchaseOrderClient = getClientForPurchasesOrdersService(transport);
const goodsReceiptClient = getClientForGoodsReceiptsService(transport);
const vendorInvoiceClient = getClientForVendorInvoicesService(transport);
const purchaseReturnClient = getClientForPurchasesReturnsService(transport);
const debitNoteClient = getClientForDebitNotesService(transport);
const purchasePaymentClient = getClientForPurchasesPaymentsService(transport);
const supplyOfferClient = getClientForSupplyOffersService(transport);

function handleRefFromChange(refFromElementId: string, refIdElementId: string, ctx: "init" | "second") {
    const refFromElement = <HTMLSelectElement>document.getElementById(refFromElementId);
    const refFrom = parseInt(refFromElement.value);

    const label = toTitleCase(decodeVendorStreamRefFrom(refFrom).split("-").join(" "));

    if (ctx == "init") {
        document.getElementById(refIdElementId)?.parentElement?.parentElement?.replaceWith(renderSelect({ id: refIdElementId, readonly: false, label: `${label} Reference`, dataMapper: "refId", dataType: "bigint", value: "", mdColSpan: 6, helpText: `Reference of the ${label}.`, options: [], dataRegex: ".+" }));
    } else if (ctx == "second") {
        document.getElementById(refIdElementId)?.parentElement?.parentElement?.parentElement?.parentElement?.replaceWith(renderSelect({ id: refIdElementId, readonly: false, label: `${label} Reference`, dataMapper: "refId", dataType: "bigint", value: "", mdColSpan: 6, helpText: `Reference of the ${label}.`, options: [], dataRegex: ".+" }));
    }

    if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_PURCHASE_ORDER) {
        setupPurchaseOrderSearchable(refIdElementId, purchaseOrderClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_GOODS_RECEIPT) {
        setupGoodsReceiptSearchable(refIdElementId, goodsReceiptClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED }, false);
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_VENDOR_INVOICE) {
        setupVendorInvoiceSearchable(refIdElementId, vendorInvoiceClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_PURCHASE_RETURN) {
        setupPurchaseReturnSearchable(refIdElementId, purchaseReturnClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_DEBIT_NOTE) {
        setupDebitNoteSearchable(refIdElementId, debitNoteClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_PURCHASE_PAYMENT) {
        setupPurchasePaymentSearchable(refIdElementId, purchasePaymentClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_SUPPLY_OFFER) {
        setupSupplyOfferSearchable(refIdElementId, supplyOfferClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    }
}

function renderReferencesSection(accessClient: PromiseClient<typeof VendorStreamsService>) {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Title", inputType: "text", dataMapper: "title", dataType: "string", value: "", mdColSpan: 12, helpText: "Title of the record.", dataRegex: ".+" }));

    // Ref from
    const refFromElementId = randomId();
    contentGrid.appendChild(renderSelectForEntireEnum({
        id: refFromElementId,
        label: "Ref From",
        nameSplitsAt: "VENDOR_STREAM_REF_FROM_",
        enumObject: VENDOR_STREAM_REF_FROM,
        dataMapper: "refFrom",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Select the reference on the basis of which the stream will be created",
        excludeZeroethValuedEnum: true,
        readonly: false,
        dataRegex: ".+",
    }));

    // Reference
    const refIdElementId = randomId();    
    contentGrid.appendChild(renderSelect({ id: refIdElementId, readonly: false, label: "Purchase Order Reference", dataMapper: "refId", dataType: "bigint", value: "", mdColSpan: 6, helpText: "Reference of the Purchase Order.", options: [], dataRegex: ".+", }));

    contentGrid.appendChild(emptyDiv());
    
    return {
        grid,
        refFromElementId,
        refIdElementId
    };
}

async function getForm(accessClient: PromiseClient<typeof VendorStreamsService>, userRole: Role, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    formGrid.id = randomId()

    const [
        referencesSection,
    ] = await Promise.all([
        renderReferencesSection(accessClient),
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-end");


    // let buttonsDiv = document.createElement("div");
    // buttonsDiv.className = `col-span-12 pl-4 float-right`;
    let nextButtonId = randomId();
    if (checkForAnyPerm(userRole, [PermissionVendorStreamNew])) {
        let nextButton = document.createElement("button");
        nextButton.id = nextButtonId;
        nextButton.className = "btn btn-outline btn-success btn-md mr-4";
        nextButton.innerText = `Next`;
        buttonContainer.append(nextButton);
    }

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonContainer.append(resetButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: document.title }).outerHTML}
            </div>
            <form>
                <hr class="m-5">
                ${referencesSection.grid.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        <div class="rounded-t mb-0 px-0 border-0">
            ${buttonContainer.outerHTML}
        </div>
    `;

    return { formGrid, nextButtonId, resetButtonId, 
        refFromElementId: referencesSection.refFromElementId,
        refIdElementId: referencesSection.refIdElementId
    };
}