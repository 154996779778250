import { context } from "./../../router";
import { OutwardJobFreeIssueMaterialReturn, OutwardJobsFreeIssueMaterialsReturnsService, OutwardJobFreeIssueMaterialReturnAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, OutwardJob, OutwardJobsService, OutwardJobAncillaryParameters, getClientForOutwardJobsFreeIssueMaterialsReturnsService, getClientForLocationsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForOutwardJobsService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForOutwardJob, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, internationalizeMoney, randomId, returnAssociateBadge, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

const downloadPDFButtonClass = "__download-pdf-btn";
// const downloadCSVButtonClass = "__download-csv-btn";
const downloadLabelButtonClass = "__download-label-btn";
const goToOutwardJobButtonClass = "__go-to-outward-job-btn";

export async function handleIndividualOutwardJobFreeIssueMaterialReturn(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForOutwardJobsFreeIssueMaterialsReturnsService(transport);
    const [outwardjobfreeissuematerialreturn, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
    ]);

    document.title = outwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? outwardjobfreeissuematerialreturn.finalRefNumber : outwardjobfreeissuematerialreturn.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    const { formGrid } = await getForm(outwardjobfreeissuematerialreturn, ancillaryParams, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup Label downloads
    let labelDownloadButtons = container.getElementsByClassName(downloadLabelButtonClass);
    for (let i = 0; i < labelDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>labelDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadLabelByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".label", ""));
        });
    }

    // Setup CSV downloads
    // let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    // for (let i = 0; i < csvDownloadButtons.length; i++) {
    //     let btn = <HTMLButtonElement>csvDownloadButtons[i];
    //     btn.addEventListener("click", async evt => {
    //         evt.preventDefault();

    //         const originalButtonHTML = btn.innerHTML;
    //         btn.disabled = true;
    //         btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
    //         let file = await accessClient.downloadItemsAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

    //         btn.disabled = false;
    //         btn.innerHTML = originalButtonHTML;

    //         downloadData(file.content, "csv", file.name.replace(".csv", ""));
    //     });
    // }

}

async function renderReferencesSection(outwardjobfreeissuematerialreturn: OutwardJobFreeIssueMaterialReturn, ancillaryParams: OutwardJobFreeIssueMaterialReturnAncillaryParameters, outwardJob: OutwardJob, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: outwardjobfreeissuematerialreturn.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: outwardjobfreeissuematerialreturn.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(outwardjobfreeissuematerialreturn.status)), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Outward Job Reference", inputType: "text", dataMapper: "refId", dataType: "string", value: outwardJob.approvalMetadata?.approvedOn! > 0 ? outwardJob.finalRefNumber : outwardJob.referenceId, mdColSpan: 6, helpText: "Reference of the Outward Job.", dataRegex: ".+" }));

    return grid;
}

async function renderBuyerAndConsigneeSection(outwardjobfreeissuematerialreturn: OutwardJobFreeIssueMaterialReturn, ancillaryParams: OutwardJobAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Consignee Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee.", dataRegex: ".+" }));

    return grid;
}

async function renderDatesSection(outwardjobfreeissuematerialreturn: OutwardJobFreeIssueMaterialReturn, ancillaryParams: OutwardJobFreeIssueMaterialReturnAncillaryParameters, outwardJob: OutwardJob, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Outward Job Free Issue Material Return Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(outwardjobfreeissuematerialreturn.metadata?.createdAt!), mdColSpan: 4, helpText: "The creation date of this Outward Job Free Issue Material Return.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Outward Job Free Issue Material Return Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(outwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn!), mdColSpan: 4, helpText: "The approval date of this Outward Job Free Issue Material Return.", dataRegex: ".*" }));

    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Outward Job Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(outwardJob.metadata?.createdAt!), mdColSpan: 4, helpText: "The creation date of the associated Outward Job.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Outward Job Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(outwardJob.approvalMetadata?.approvedOn!), mdColSpan: 4, helpText: "The approval date of the associated Outward Job.", dataRegex: ".*" }));

    return grid;
}

async function renderContactsSection(outwardjob: OutwardJob, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewOutwardJobContacts({ uuid: outwardjob.metadata?.uuid })).list;
    let outwardJobAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    outwardJobAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(outwardjobfreeissuematerialreturn: OutwardJobFreeIssueMaterialReturn, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (outwardjobfreeissuematerialreturn.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(outwardjobfreeissuematerialreturn.formData, "outward-jobs-free-issue-materials-returns", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(outwardjobfreeissuematerialreturn: OutwardJobFreeIssueMaterialReturn, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(outwardjobfreeissuematerialreturn.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });
    
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = outwardjobfreeissuematerialreturn.list.map(p => p.vendorUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    outwardjobfreeissuematerialreturn.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Vendor Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorQuantity)))} (${uomsMap.get(item.vendorUomId)?.symbol}) ${uomsMap.get(item.vendorUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Vendor's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(outwardjobfreeissuematerialreturn: OutwardJobFreeIssueMaterialReturn, ancillaryParams: OutwardJobFreeIssueMaterialReturnAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const outwardJobsClient = getClientForOutwardJobsService(transport);
    const [outwardJob, outwardJobAncillaryParams] = await Promise.all([
        outwardJobsClient.viewByUUID({ uuid: ancillaryParams.refUuid }),
        outwardJobsClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        contactsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(outwardjobfreeissuematerialreturn, ancillaryParams, outwardJob, readonly, accessClient),
        renderBuyerAndConsigneeSection(outwardjobfreeissuematerialreturn, outwardJobAncillaryParams, readonly, accessClient, transport),
        renderDatesSection(outwardjobfreeissuematerialreturn, ancillaryParams, outwardJob, readonly, accessClient),
        renderContactsSection(outwardJob, readonly, outwardJobsClient, transport),
        renderDynamicFormsSection(outwardjobfreeissuematerialreturn, readonly, accessClient),
        renderFamiliesSection(outwardjobfreeissuematerialreturn, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", outwardjobfreeissuematerialreturn.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", outwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? outwardjobfreeissuematerialreturn.finalRefNumber : outwardjobfreeissuematerialreturn.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download Label button
    let downloadLabelButton = document.createElement("button");
    downloadLabelButton.id = randomId();
    downloadLabelButton.className = `btn btn-success btn-outline btn-sm ${downloadLabelButtonClass} mr-4`;
    downloadLabelButton.innerText = "Download Labels";
    downloadLabelButton.setAttribute("data-uuid", outwardjobfreeissuematerialreturn.metadata?.uuid!);
    downloadLabelButton.setAttribute("data-name", outwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? outwardjobfreeissuematerialreturn.finalRefNumber : outwardjobfreeissuematerialreturn.referenceId);
    buttonContainer.appendChild(downloadLabelButton);

    // Download CSV button
    // let downloadCSVButton = document.createElement("button");
    // downloadCSVButton.id = randomId();
    // downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    // downloadCSVButton.innerText = "Download CSV";
    // downloadCSVButton.setAttribute("data-uuid", outwardjobfreeissuematerialreturn.metadata?.uuid!);
    // downloadCSVButton.setAttribute("data-name", outwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? outwardjobfreeissuematerialreturn.finalRefNumber : outwardjobfreeissuematerialreturn.referenceId);
    // buttonContainer.appendChild(downloadCSVButton);

    let goToOutwardJobButton = document.createElement("a");
    goToOutwardJobButton.className = `btn btn-info btn-outline btn-sm ${goToOutwardJobButtonClass} mr-4`;
    goToOutwardJobButton.innerText = "Go To Outward Job";
    goToOutwardJobButton.href = getLinkForOutwardJob(outwardJob.metadata!.uuid)
    buttonContainer.appendChild(goToOutwardJobButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Outward Job Free Issue Material Return: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ? 
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>` 
        : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}
