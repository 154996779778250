import { context } from "./../../router";
import { GoodsReceipt, GoodsReceiptsService, GoodsReceiptAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, PurchaseOrder, PurchasesOrdersService, PurchaseOrderAncillaryParameters, getClientForGoodsReceiptsService, getClientForLocationsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForPurchasesOrdersService, PermissionGoodsReceiptDrafts, PermissionGoodsReceiptRevise, STANDARD_LIFECYCLE_STATUS, GoodsReceiptsServiceUpdateRequest, getClientForFamiliesService, GoodsReceiptsServiceItemCreateRequest, getClientForVendorInvoicesService, GoodsReceiptsServiceAutofillRequest, IdentifierUUIDWithFile } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForGoodsReceipt, getLinkForPurchaseOrder, getLinkForVendorInvoice, redirectTo, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSelect, renderSpan, returnFormFromFields } from "../../ui";
import { _returnInCents, checkForAnyPerm, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, decodeSLC, downloadData, getDynamicFormData, getUserRole, handleCSVFileUpload, internationalizeMoney, randomId, returnAssociateBadge, showFailureAlert, showSuccessAlert, toSelectableDate, toTitleCase, validateForm } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs, purchaseorderFamily, roleSelf } from "../../fetches";
import { getTransport, getWriteTransport } from "../../clients";
import { setupFamilySearchable } from "../../searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { renderGoodsReceiptItemTrends } from "./trends";

const downloadPDFButtonClass = "__download-pdf-btn";
const downloadCSVButtonClass = "__download-csv-btn";
const downloadLabelButtonClass = "__download-label-btn";
const goToPurchaseOrderButtonClass = "__go-to-purchase-order-btn";
const updateButtonClass = "__update-btn";
const autofillButtonClass = "__autofill-btn";
const uploadCSVButtonClass = "__upload-csv-btn";
const addLineItemButtonClass = "__add-line-item-btn";
const deleteLineItemButtonClass = "__delete-line-item-btn";
const trendsLineItemButtonClass = "__trends-line-item-btn";

export async function handleIndividualGoodsReceipt(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForGoodsReceiptsService(transport);
    const [goodsreceipt, ancillaryParams, userRole] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
        getUserRole(),
    ]);

    document.title = goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    let readonly = true;
    if (checkForAnyPerm(userRole, [PermissionGoodsReceiptDrafts]) && goodsreceipt.status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
        readonly = false;
    } else if (checkForAnyPerm(userRole, [PermissionGoodsReceiptRevise]) && goodsreceipt.status == STANDARD_LIFECYCLE_STATUS.REVISION) {
        readonly = false;
    }

    let { formGrid, buttonContainer } = await getForm(goodsreceipt, ancillaryParams, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup Label downloads
    let labelDownloadButtons = container.getElementsByClassName(downloadLabelButtonClass);
    for (let i = 0; i < labelDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>labelDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadLabelByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".label", ""));
        });
    }

    // Setup CSV downloads
    let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    for (let i = 0; i < csvDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>csvDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadItemsAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

    const familiesAccessClient = getClientForFamiliesService(transport);

    let trendsLineItemButtons = container.getElementsByClassName(trendsLineItemButtonClass);
    for (let i = 0; i < trendsLineItemButtons.length; i++) {
        let btn = <HTMLButtonElement>trendsLineItemButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                renderGoodsReceiptItemTrends(protoInt64.parse(btn.getAttribute("data-family-id") || "0"), btn.getAttribute("data-family-uuid") || "", accessClient, familiesAccessClient, transport);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }

    if (!readonly) {
        const writeClient = getClientForGoodsReceiptsService(getWriteTransport());
        const uomAccessClient = getClientForUnitsOfMaterialsService(transport);
        const purchaseOrderAccessClient = getClientForPurchasesOrdersService(transport);

        // Setup update button handler
        let updateButton = container.getElementsByClassName(updateButtonClass)[0] as HTMLButtonElement;
        updateButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Validate all the fields
            if (!validateForm(formGrid.id)) {
                return;
            }

            // Create the object
            let obj = <GoodsReceiptsServiceUpdateRequest>createObjectFromForm(formGrid.id);
            obj.formData = getDynamicFormData();
            obj.id = goodsreceipt.metadata?.id!;
            obj.notifyUsers = true;

            try {
                if (goodsreceipt.status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
                    await writeClient.draftUpdate(obj);
                } else if (goodsreceipt.status == STANDARD_LIFECYCLE_STATUS.REVISION) {
                    await writeClient.revisionUpdate(obj);
                }
                showSuccessAlert(`Goods Receipt: ${obj.referenceId} has been updated`);
                // No need to redirect, since the UI will auto update on receipt of notification from the server
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup autofill button handler
        let autofillButton = container.getElementsByClassName(autofillButtonClass)[0] as HTMLButtonElement;
        autofillButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Create the object
            let obj = <GoodsReceiptsServiceAutofillRequest>{};
            obj.uuid = goodsreceipt.metadata?.uuid!;

            try {
                await writeClient.autofill(obj);
                showSuccessAlert(`Goods Receipt: ${goodsreceipt.referenceId} has been autofilled`);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup upload CSV button handler
        let uploadCSVButton = container.getElementsByClassName(uploadCSVButtonClass)[0] as HTMLButtonElement;
        uploadCSVButton.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                handleCSVFileUpload(async fileContent => {
                    // Create the object
                    let obj = <IdentifierUUIDWithFile>{};
                    obj.uuid = goodsreceipt.metadata?.uuid!;
                    obj.fileContent = fileContent;
                    await writeClient.uploadGoodsReceiptItems(obj);
                    showSuccessAlert(`Goods Receipt: ${goodsreceipt.referenceId} has been uploaded from the given CSV file`);
                });
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup add line item button handlers
        let addLineItemButton = container.getElementsByClassName(addLineItemButtonClass)[0] as HTMLButtonElement;
        addLineItemButton.addEventListener("click", async evt => {
            evt.preventDefault();

            const familyIdElId = randomId();
            const internalQtyElId = randomId();
            const vendorUomElId = randomId();
            const vendorQtyElId = randomId();
            let submitBtnElId = randomId();

            let submitButton = document.createElement("button");
            submitButton.id = submitBtnElId;
            submitButton.innerText = "Submit";
            submitButton.className = "btn btn-success btn-outline btn-sm mt-3";

            // Show a dialog
            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();
            dialog.innerHTML = `
            <div class="max-w-fit h-96 overflow-auto modal-box bg-white text-gray-700">
                <p class="text-2xl text-center">Add Line Item</p>
                <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <div class="grid grid-cols-12 gap-2">
                    ${renderSelect({ id: familyIdElId, readonly: false, label: "Select Material", dataMapper: "familyId", dataType: "bigint", value: "", mdColSpan: 12, helpText: "Select the material to add", options: [], dataRegex: ".+" }).outerHTML}
                    ${renderInput({ id: vendorUomElId, readonly: true, label: "Vendor UoM", inputType: "text", dataMapper: "", dataType: "string", value: "", mdColSpan: 4, helpText: "Vendor UoM", dataRegex: ".*" }).outerHTML}
                    ${renderInput({ id: vendorQtyElId, readonly: false, label: "Vendor Quantity", inputType: "number", dataMapper: "vendorQuantity", dataType: "bigint_in_cents", value: "", mdColSpan: 4, helpText: "Vendor Quantity (Quantity in the Vendor's UoM)", dataRegex: "^(\\d*\\.\\d{1,2}|\\d+)$" }).outerHTML}
                    ${renderInput({ id: internalQtyElId, readonly: false, label: "Internal Quantity", inputType: "number", dataMapper: "internalQuantity", dataType: "bigint_in_cents", value: "", mdColSpan: 4, helpText: "Internal Quantity (Quantity in the primary UoM)", dataRegex: "^(\\d*\\.\\d{1,2}|\\d+)$" }).outerHTML}

                    ${submitButton.outerHTML}
                </div>
            </div>
            `;

            document.body.appendChild(dialog);
            dialog.showModal();

            let internalQtyEl = (<HTMLInputElement>document.getElementById(internalQtyElId));
            let vendorQtyEl = (<HTMLInputElement>document.getElementById(vendorQtyElId));
            let vendorUomEl = (<HTMLInputElement>document.getElementById(vendorUomElId));

            setupFamilySearchable(familyIdElId, async (searchKey, choice) => {
                let results = await accessClient.viewProspectiveFamilies({
                    id: goodsreceipt.metadata?.id,
                    searchKey: searchKey
                });

                choice.setChoices(results.list.map(r => {
                    return { value: r.metadata?.id, label: `(${r.code}) ${r.name}`, disabled: false };
                }));

            }, async (value) => {
                let [item, purchaseorderItem] = await Promise.all([
                    accessClient.viewProspectiveGoodsReceiptItem({
                        goodsReceiptId: goodsreceipt.metadata?.id!,
                        familyId: protoInt64.parse(value),
                    }),
                    purchaseorderFamily(goodsreceipt.refId, protoInt64.parse(value), purchaseOrderAccessClient)
                ]);

                let uom = new UnitOfMaterial({ symbol: "-", name: "-", metadata: { id: protoInt64.zero } });
                if (purchaseorderItem.vendorUomId > 0) {
                    uom = await uomAccessClient.viewByID({ id: purchaseorderItem.vendorUomId });
                }

                internalQtyEl.value = convertCentsToMoney(item.internalQuantity);
                vendorQtyEl.value = convertCentsToMoney(item.vendorQuantity);
                vendorUomEl.value = `(${uom.symbol}) ${uom.name}`;
                vendorUomEl.setAttribute("data-id", `${uom.metadata?.id}`);
            });

            (<HTMLButtonElement>document.getElementById(submitBtnElId)).addEventListener("click", async evt => {
                evt.preventDefault();

                if (!validateForm(dialog.id)) {
                    return;
                }
                let itemToAdd = new GoodsReceiptsServiceItemCreateRequest({
                    goodsReceiptId: goodsreceipt.metadata?.id,
                    familyId: protoInt64.parse((<HTMLInputElement>document.getElementById(familyIdElId)).value),
                    internalQuantity: protoInt64.parse(_returnInCents(parseFloat(internalQtyEl.value))),
                    vendorUomId: protoInt64.parse(vendorUomEl.getAttribute("data-id") || "0"),
                    vendorQuantity: protoInt64.parse(_returnInCents(parseFloat(vendorQtyEl.value))),
                });

                try {
                    await writeClient.addGoodsReceiptItem(itemToAdd);
                    showSuccessAlert(`Item added to Goods Receipt: ${goodsreceipt.referenceId}`);
                    dialog.close();
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });

        });

        let deleteLineItemButtons = container.getElementsByClassName(deleteLineItemButtonClass);
        for (let i = 0; i < deleteLineItemButtons.length; i++) {
            let btn = <HTMLButtonElement>deleteLineItemButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                try {
                    await writeClient.deleteGoodsReceiptItem({
                        id: protoInt64.parse(btn.getAttribute("data-id") || "0"),
                    });
                    showSuccessAlert(`Item deleted from Goods Receipt: ${goodsreceipt.referenceId}`);
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });
        }

        // ---------------------------------------------------------------------------------------------------------
        buttonContainer = <HTMLDivElement>document.getElementById(buttonContainer.id);

        let sendForVerificationButton = document.createElement("button");
        sendForVerificationButton.id = randomId();
        sendForVerificationButton.className = `btn btn-warning btn-outline btn-sm mr-4`;
        sendForVerificationButton.innerText = "Send for Verification";
        sendForVerificationButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
        sendForVerificationButton.setAttribute("data-id", goodsreceipt.metadata!.id.toString());
        sendForVerificationButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
        buttonContainer.appendChild(sendForVerificationButton);

        sendForVerificationButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = sendForVerificationButton.innerHTML;
            sendForVerificationButton.disabled = true;
            sendForVerificationButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.sendForVerification({ uuid: sendForVerificationButton.getAttribute("data-uuid") || "" });
            sendForVerificationButton.disabled = false;
            sendForVerificationButton.innerHTML = originalButtonHTML;
        });
    }
}

async function renderReferencesSection(goodsreceipt: GoodsReceipt, ancillaryParams: GoodsReceiptAncillaryParameters, purchaseOrder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: goodsreceipt.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: goodsreceipt.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(goodsreceipt.status)), mdColSpan: 4, helpText: "Status of the record.", dataRegex: ".+" }));

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Vendor Bill No", inputType: "text", dataMapper: "vendorBillNo", dataType: "string", value: goodsreceipt.vendorBillNo, mdColSpan: 4, helpText: "Vendor Bill No is the sequence number that was generated by the vendor.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Vendor Bill Date", inputType: "date", dataMapper: "vendorBillDate", dataType: "string", value: toSelectableDate(goodsreceipt.vendorBillDate), mdColSpan: 4, helpText: "Vendor Bill Date is the date on which the bill has been generated.", dataRegex: ".+" }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Purchase Order Reference", inputType: "text", dataMapper: "refId", dataType: "string", value: purchaseOrder.approvalMetadata?.approvedOn! > 0 ? purchaseOrder.finalRefNumber : purchaseOrder.referenceId, mdColSpan: 6, helpText: "Reference of the Purchase Order.", dataRegex: ".+" }));

    return grid;
}

async function renderBuyerAndConsigneeSection(goodsreceipt: GoodsReceipt, ancillaryParams: PurchaseOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [buyer, consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerLocationUuid }),
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Buyer", inputType: "text", dataMapper: "buyerLocationId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee.", dataRegex: ".*" }));

    return grid;
}

async function renderDatesSection(goodsreceipt: GoodsReceipt, ancillaryParams: GoodsReceiptAncillaryParameters, purchaseOrder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Goods Receipt Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(goodsreceipt.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this Goods Receipt.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Goods Receipt Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(goodsreceipt.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this Goods Receipt.", dataRegex: ".*" }));

    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Purchase Order Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(purchaseOrder.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of the associated Purchase Order.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Purchase Order Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(purchaseOrder.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of the associated Purchase Order.", dataRegex: ".*" }));

    return grid;
}

async function renderInvoiceSection(goodsreceipt: GoodsReceipt, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Invoice Details", titleMdColSpan: 3 });


    let [isBilled, vendorInvoiceInfo] = await Promise.all([
        accessClient.isBilled({ uuid: goodsreceipt.metadata?.uuid }),
        accessClient.viewAssociatedVendorInvoiceInfo({ uuid: goodsreceipt.metadata?.uuid }),
    ]);

    if (!isBilled.value) {
        return grid;
    }

    const vendorinvoicesClient = getClientForVendorInvoicesService(transport);
    let vendorinvoice = await vendorinvoicesClient.viewByUUID({ uuid: vendorInvoiceInfo.uuid });

    let vendorInvoiceLink = document.createElement("a");
    vendorInvoiceLink.className = `btn btn-wide btn-outline btn-primary text-white truncate`;
    vendorInvoiceLink.innerHTML = `<i class='bx bx-door-open'></i> ${vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId}`;
    vendorInvoiceLink.href = getLinkForVendorInvoice(vendorinvoice.metadata?.uuid!);

    contentGrid.appendChild(vendorInvoiceLink);
    return grid;
}

async function renderContactsSection(purchaseorder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewPurchaseOrderContacts({ uuid: purchaseorder.metadata?.uuid })).list;
    let purchaseOrderAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    purchaseOrderAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(goodsreceipt: GoodsReceipt, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (goodsreceipt.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(goodsreceipt.formData, "goods-receipts", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(goodsreceipt: GoodsReceipt, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    if (!readonly) {
        let button = document.createElement("button");
        button.type = "button";
        button.className = `btn btn-sm btn-primary text-white justify-right ${addLineItemButtonClass}`;
        button.innerHTML = `<i class='bx bx-plus'></i> Add Line Item`;
        containerTitle.firstChild!.appendChild(button);

        let autofillButton = document.createElement("button");
        autofillButton.id = randomId();
        autofillButton.className = `btn btn-sm btn-warning btn-outline justify-right ${autofillButtonClass} ml-4`;
        autofillButton.innerHTML = `<i class='bx bxs-magic-wand'></i> AutoFill`;
        autofillButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
        autofillButton.setAttribute("data-id", goodsreceipt.metadata!.id.toString());
        autofillButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
        containerTitle.firstChild!.appendChild(autofillButton);

        let uploadCSVButton = document.createElement("button");
        uploadCSVButton.id = randomId();
        uploadCSVButton.className = `btn btn-sm btn-info btn-outline justify-right ${uploadCSVButtonClass} ml-4`;
        uploadCSVButton.innerHTML = `<i class='bx bx-upload'></i> Upload from CSV`;
        uploadCSVButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
        uploadCSVButton.setAttribute("data-id", goodsreceipt.metadata!.id.toString());
        uploadCSVButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
        containerTitle.firstChild!.appendChild(uploadCSVButton);
    }

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(goodsreceipt.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });

    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = goodsreceipt.list.map(p => p.vendorUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    goodsreceipt.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Vendor Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorQuantity)))} (${uomsMap.get(item.vendorUomId)?.symbol}) ${uomsMap.get(item.vendorUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Vendor's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4";
        contentGrid.appendChild(buttonsDiv);
        contentGrid.appendChild(emptyDiv());

        if (!readonly) {
            let deleteItemButton = document.createElement("button");
            deleteItemButton.type = "button";
            deleteItemButton.className = `btn btn-sm btn-error text-white float-right ${deleteLineItemButtonClass}`;
            deleteItemButton.innerHTML = `<i class='bx bx-trash'></i> Delete`;
            deleteItemButton.setAttribute("data-id", item.metadata?.id.toString()!);

            buttonsDiv.appendChild(deleteItemButton);
        }

        // Trends button here
        let trendsItemButton = document.createElement("button");
        trendsItemButton.type = "button";
        trendsItemButton.className = `btn btn-sm btn-primary btn-outline text-white float-right mr-3 ${trendsLineItemButtonClass}`;
        trendsItemButton.innerHTML = `<i class='bx bx-line-chart'></i> Trends`;
        trendsItemButton.setAttribute("data-id", item.metadata?.id.toString()!);
        trendsItemButton.setAttribute("data-family-id", item.familyId?.toString()!);
        trendsItemButton.setAttribute("data-family-uuid", item.familyUuid!);

        buttonsDiv.appendChild(trendsItemButton);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(goodsreceipt: GoodsReceipt, ancillaryParams: GoodsReceiptAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof GoodsReceiptsService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    formGrid.id = randomId();

    const purchasesOrdersClient = getClientForPurchasesOrdersService(transport);
    const [purchaseOrder, purchaseOrderAncillaryParams] = await Promise.all([
        purchasesOrdersClient.viewByUUID({ uuid: ancillaryParams.refUuid }),
        purchasesOrdersClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        contactsSection,
        invoiceSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(goodsreceipt, ancillaryParams, purchaseOrder, readonly, accessClient),
        renderBuyerAndConsigneeSection(goodsreceipt, purchaseOrderAncillaryParams, readonly, accessClient, transport),
        renderDatesSection(goodsreceipt, ancillaryParams, purchaseOrder, readonly, accessClient),
        renderContactsSection(purchaseOrder, readonly, purchasesOrdersClient, transport),
        renderInvoiceSection(goodsreceipt, readonly, accessClient, transport),
        renderDynamicFormsSection(goodsreceipt, readonly, accessClient),
        renderFamiliesSection(goodsreceipt, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.id = randomId();
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    if (!readonly) {
        let updateButton = document.createElement("button");
        updateButton.id = randomId();
        updateButton.className = `btn btn-warning btn-outline btn-sm ${updateButtonClass} mr-4`;
        updateButton.innerText = "Update";
        updateButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
        updateButton.setAttribute("data-id", goodsreceipt.metadata!.id.toString());
        updateButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
        buttonContainer.appendChild(updateButton);
    }

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download Label button
    let downloadLabelButton = document.createElement("button");
    downloadLabelButton.id = randomId();
    downloadLabelButton.className = `btn btn-success btn-outline btn-sm ${downloadLabelButtonClass} mr-4`;
    downloadLabelButton.innerText = "Download Labels";
    downloadLabelButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
    downloadLabelButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
    buttonContainer.appendChild(downloadLabelButton);

    // Download CSV button
    let downloadCSVButton = document.createElement("button");
    downloadCSVButton.id = randomId();
    downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    downloadCSVButton.innerText = "Download CSV";
    downloadCSVButton.setAttribute("data-uuid", goodsreceipt.metadata?.uuid!);
    downloadCSVButton.setAttribute("data-name", goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId);
    buttonContainer.appendChild(downloadCSVButton);

    let goToPurchaseOrderButton = document.createElement("a");
    goToPurchaseOrderButton.className = `btn btn-info btn-outline btn-sm ${goToPurchaseOrderButtonClass} mr-4`;
    goToPurchaseOrderButton.innerText = "Go To Purchase Order";
    goToPurchaseOrderButton.href = getLinkForPurchaseOrder(purchaseOrder.metadata!.uuid)
    buttonContainer.appendChild(goToPurchaseOrderButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Goods Receipt: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form>
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${invoiceSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid, buttonContainer }
}
